<template>
  <div>
    <base-header class="pb-6" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
    </base-header>

    <div class="container-fluid mt--6">
      <table-navigation :table-title="tableTitle"
                        :table-buttons="tableButtons"
                        :table-columns="tableColumns"
                        :table-data="tableData"
                        :router-view="routerView"
                        :connection-error="connectionError"
                        :pagination="pagination"
                        @page="changePage"
      >
      </table-navigation>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
import TableNavigation from "@/views/Store/Tables/TableNavigation";
import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";

const {mapState, mapActions} = createNamespacedHelpers('ServiceListModule')

export default {
  name: 'ServiceList',
  components: {
    BreadcrumbHeader,
    TableNavigation,
  },
  data() {
    return {
      tableTitle: 'Lista usług',
      routerView: 'service-view',
      tableButtons: [
        {
          label: 'Dodaj usługę',
          icon: 'fas fa-edit',
          link: 'service-add',
        },
      ],
      tableColumns: [
        {
          prop: 'name',
          label: 'Nazwa',
          minWidth: 150,
          sortable: true,
          link: true,
        },
        {
          prop: 'price',
          label: 'Kwota',
          minWidth: 60,
          sortable: false,
        },
        {
          prop: 'fixedPrice',
          label: 'Stała kwota',
          minWidth: 60,
          sortable: false,
        },
        {
          prop: 'active',
          label: 'Aktywny',
          minWidth: 60,
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState({
      tableData: state => state.items,
      totalItems: state => state.totalItems,
      connectionError: state => state.apiConnectionErr
    }),
    breadcrumb() {
      return [
        {title: 'Sklep', link: {name: 'store'}},
        {title: 'Usługi'},
      ];
    },
    handleError() {
      return `Błąd ${this.connectionError.status} - ${this.$t[this.connectionError.status]}`;
    },
    pagination() {
      return {
        totalItems: this.totalItems,
        perPage: 20,
        currentPage: parseInt(this.$route.params.pageNum || 1),
      };
    },
  },
  methods: {
    changePage(page) {
      this.$router.push({name: 'service-list', params: {pageNum: page}});
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('ServiceListModule/resetItems', []);
    return next()
  },
  beforeRouteUpdate(to, from, next) {
    this.$store.commit('ServiceListModule/resetItems', []);
    this.$store.dispatch('ServiceListModule/fetchList', {
      token: this.$store.state.Auth.token,
      page: to.params.pageNum || 1,
    });
    return next();
  },
  beforeCreate() {
    this.$store.dispatch('ServiceListModule/fetchList', {
      token: this.$store.state.Auth.token,
      page: this.$route.params.pageNum || 1,
    });
  }
};
</script>
